<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Technic" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                  :class="{'is-invalid': isTabInvalid(title)}"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-card class="mb-3" title="Main screen background image *">
                  <div v-if="mainBgImgExisting">
                    <a style="display: block;" target="_blank" :href="mainBgImgExisting"><img style="max-width: 400px;" :src="mainBgImgExisting"></a>
                    <div><va-button small color="danger" @click="mainBgImgExisting = deleteImage(mainBgImgId, 'technicpage_screen_image') ? '' : mainBgImgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.technicpage_screen_image"
                    v-model="mainBgImg"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.technicpage_screen_image" style="color: red;">{{ errors.technicpage_screen_image[0] }}</div>
                  <va-button @click="uploadImage('technicpage_screen_image', 'technicpage.screen.image', mainBgImg[0])">Завантажити</va-button>
                </va-card>
                <va-card class="mb-3" title="Main screen background mobile image *">
                  <div v-if="mainBgImgMobileExisting">
                    <a style="display: block;" target="_blank" :href="mainBgImgMobileExisting"><img style="max-width: 400px;" :src="mainBgImgMobileExisting"></a>
                    <div><va-button small color="danger" @click="mainBgImgMobileExisting = deleteImage(mainBgImgMobileId, 'technicpage_screen_mobile_image') ? '' : mainBgImgMobileExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.technicpage_screen_mobile_image"
                    v-model="mainBgImgMobile"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.technicpage_screen_mobile_image" style="color: red;">{{ errors.technicpage_screen_mobile_image[0] }}</div>
                  <va-button @click="uploadImage('technicpage_screen_mobile_image', 'technicpage.screen.mobile.image', mainBgImgMobile[0])">Завантажити</va-button>
                </va-card>
                <va-input
                  label="Main screen title *"
                  v-model="mainTitle"
                  :messages="['The recommended number of characters is 10']"
                  :error="!!errors.technicpage_screen_title"
                  :error-messages="errors.technicpage_screen_title"
                  @input="delete errors.technicpage_screen_title"
                />
                <va-input
                  label="Main screen description *"
                  v-model="mainDescription"
                  :type="'textarea'"
                  :messages="['The recommended number of characters is 550']"
                  :error="!!errors.technicpage_screen_description"
                  :error-messages="errors.technicpage_screen_description"
                  @input="delete errors.technicpage_screen_description"
                />
                <va-input
                  label="Scroll title *"
                  v-model="mainScroll"
                  :messages="['The recommended number of characters is 191']"
                  :error="!!errors.technicpage_scroll_title"
                  :error-messages="errors.technicpage_scroll_title"
                  @input="delete errors.technicpage_scroll_title"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 1">
                <va-input
                  label="Advantages title *"
                  v-model="advantagesTitle"
                  :messages="['The recommended number of characters is 20']"
                  :error="!!errors.technicpage_advantage_title"
                  :error-messages="errors.technicpage_advantage_title"
                  @input="delete errors.technicpage_advantage_title"
                />
                <va-input
                  label="Advantages description *"
                  v-model="advantagesDescription"
                  :type="'textarea'"
                  :messages="['The recommended number of characters is 250']"
                  :error="!!errors.technicpage_advantage_description"
                  :error-messages="errors.technicpage_advantage_description"
                  @input="delete errors.technicpage_advantage_description"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 2">
                <va-input
                  label="Machinery title *"
                  v-model="machineryTitle"
                  :messages="['The recommended number of characters is 30']"
                  :error="!!errors.technicpage_machinery_title"
                  :error-messages="errors.technicpage_machinery_title"
                  @input="delete errors.technicpage_machinery_title"
                />
                <va-input
                  label="Machinery description"
                  v-model="machineryDescription"
                  :type="'textarea'"
                  :messages="['The recommended number of characters is 255']"
                  :error="!!errors.technicpage_machinery_description"
                  :error-messages="errors.technicpage_machinery_description"
                  @input="delete errors.technicpage_machinery_description"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  name: 'grid',
  data () {
    return {
      mainBgImg: [],
      mainBgImgId: '',
      mainBgImgExisting: '',
      mainBgImgMobile: [],
      mainBgImgMobileId: '',
      mainBgImgMobileExisting: '',
      mainTitle: '',
      mainDescription: '',
      mainScroll: '',

      advantagesTitle: '',
      advantagesDescription: '',

      machineryTitle: '',
      machineryDescription: '',

      errors: [],

      tabTitles: ['Main', 'Advantage', 'Machinery'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
    putData () {
      return {
        technicpage_screen_title: this.mainTitle,
        technicpage_screen_description: this.mainDescription,
        technicpage_scroll_title: this.mainScroll,

        technicpage_advantage_title: this.advantagesTitle,
        technicpage_advantage_description: this.advantagesDescription,

        technicpage_machinery_title: this.machineryTitle,
        technicpage_machinery_description: this.machineryDescription,
      }
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    uploadImage (param, key, file) {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append(param, file)
      formData.append('lang', this.locale)
      formData.append('key', key)
      formData.append('multiple', '0')
      formData.append('file_id', '0')
      axios.post(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/upload/15`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id, fieldKey) {
      return axios.post(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/15/delete-file/${id}`, {
        key: fieldKey,
      })
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    isTabInvalid (name) {
      const tab = this.tabTitles.findIndex(item => item === name)

      if (tab === 0) {
        return !!this.errors.technicpage_screen_title || !!this.errors.technicpage_screen_description || !!this.errors.technicpage_scroll_title ||
          !!this.errors.technicpage_screen_image || !!this.errors.technicpage_screen_mobile_image
      } else if (tab === 1) {
        return !!this.errors.technicpage_advantage_title || !!this.errors.technicpage_advantage_description
      } else if (tab === 2) {
        return !!this.errors.technicpage_machinery_title || !!this.errors.technicpage_machinery_description
      }
      return false
    },
    submit () {
      axios.put(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/15`, this.putData)
        .then(response => {
          this.showToast('Successful update')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          this.showToast('Error')
        })
    },
    validate () {
      axios.put(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/validate/15`, this.putData, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
        .then(response => {
          this.errors = response.data
          if (!Object.keys(this.errors).length) {
            this.submit()
          }
        })
        .catch(error => {
          console.log(error)
          this.showToast('Validate Fetch Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/15`)
        .then(response => {
          // console.log(response.data)
          this.mainTitle = response.data.technicpage_screen_title
          this.mainDescription = response.data.technicpage_screen_description
          this.mainScroll = response.data.technicpage_scroll_title

          this.advantagesTitle = response.data.technicpage_advantage_title
          this.advantagesDescription = response.data.technicpage_advantage_description

          this.machineryTitle = response.data.technicpage_machinery_title
          this.machineryDescription = response.data.technicpage_machinery_description

          this.mainBgImgExisting = response.data.technicpage_screen_image ? process.env.VUE_APP_AUTOSTRADA_ADMIN + response.data.technicpage_screen_image : ''
          this.mainBgImgId = response.data.technicpage_screen_image_id
          this.mainBgImgMobileExisting = response.data.technicpage_screen_mobile_image ? process.env.VUE_APP_AUTOSTRADA_ADMIN + response.data.technicpage_screen_mobile_image : ''
          this.mainBgImgMobileId = response.data.technicpage_screen_mobile_image_id
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}

.va-tab {
  &.is-invalid {
    opacity: 1 !important;
    color: red !important;
  }
}
</style>
